import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom/';
import { CircularProgress } from '@material-ui/core';

import { getUser, signInRedirect } from '@src/auth';
import { fetchActiveHierarchyNodeUserStart } from './features/hierarchyNodeUser/slices';

const AuthContext = createContext(null);

const Loader = () => (
  <div
    style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
    }}
  >
    <CircularProgress />
  </div>
);

export const AuthProvider = ({
  children,
  hierarchyNodeUserRequired = true,
}) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { contract } = useParams();
  const { hierarchyNodeUser } = useSelector(
    state => state.activeHierarchyNodeUser,
  );

  const [user, setUser] = useState(null);

  useEffect(() => {
    const hierarchyNodeUserMustBeSelected = () => {
      return (
        hierarchyNodeUserRequired &&
        contract &&
        (!hierarchyNodeUser ||
          hierarchyNodeUser._context.contract.code !== contract)
      );
    };

    const checkUserStatus = async () => {
      try {
        const currentUser = await getUser();

        if (!currentUser || currentUser.expired) {
          const encodedPath = encodeURIComponent(location.pathname);
          const redirectUri = `${window.location.origin}/callback?redirect=${encodedPath}`;
          signInRedirect(redirectUri);
        } else {
          setUser(currentUser);

          if (hierarchyNodeUserMustBeSelected()) {
            dispatch(fetchActiveHierarchyNodeUserStart({ contract }));
          }
        }
      } catch (error) {
        console.error('Error while checking user status', error);
      }
    };

    checkUserStatus();
  }, [
    contract,
    dispatch,
    hierarchyNodeUser,
    hierarchyNodeUserRequired,
    location.pathname,
  ]);

  const contextValue = useMemo(
    () => ({ hierarchyNodeUser, user }),
    [hierarchyNodeUser, user],
  );

  /**
   * Vérifie les droits d'accès de l'utilisateur à la page demandée, sans tenir compte du rôle.
   *
   * Conditions d'accès autorisées :
   *
   * 1. Accès standard :
   *    - L'utilisateur est connecté
   *    - Un profil utilisateur est obligatoire et est sélectionné
   *    - Si la page est liée à un contrat : le profil utilisateur appartient à ce contrat
   *
   * 2. Cas particulier - Activation de compte :
   *    - L'utilisateur est connecté
   *    - Le profil utilisateur n'est pas obligatoire
   *
   * @returns {boolean} True si l'accès est autorisé, false sinon
   */
  const isAuthorized = () => {
    return (
      user &&
      (!contract ||
        (hierarchyNodeUser &&
          hierarchyNodeUser._context.contract.code === contract) ||
        !hierarchyNodeUserRequired)
    );
  };

  return (
    <AuthContext.Provider value={contextValue}>
      {isAuthorized() ? children : <Loader />}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
