import React from 'react';
import { withStyles } from '@mui/styles';
import { neutralColors } from '@spider:src/themes';
import { Link, Typography } from '@material-ui/core';
import { useIntl } from 'react-intl';
import { Button } from '@spider:src/components';
import { PointPriceRatio } from '../PointPriceRatio';
import { Loader } from '@src/components';
import UseRewardConvertCard from '@spider:src/hooks/UseRewardConvertCard';

const styles = {
  rewardCard: {
    display: 'flex',
    position: 'relative',
    paddingBottom: '1.5rem',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '1.5rem',
    flexShrink: 0,
    alignSelf: 'stretch',
    borderRadius: '1rem',
    background: neutralColors.neutralWhite,
    boxShadow: '0px 6px 10px 2px rgba(10, 31, 94, 0.12)',
  },
  iconDetail: {
    display: 'flex',
    padding: '0.5rem',
    flexDirection: 'column',
    alignItems: 'center',
    borderRadius: '3.875rem',
    background: neutralColors.neutralColdWhite,
  },
  details: {
    display: 'flex',
    padding: '0rem 1rem',
    alignItems: 'flex-start',
    flexDirection: 'column',
    gap: '1rem',
  },
  detail: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    gap: '1rem',
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    gap: '0.5rem',
  },
};

const RewardConvertCard = ({
  reward,
  ComponentDecoration,
  cardDetails,
  convert,
  setOpenHelperAbout,
  setDisableConvert,
  ...props
}) => {
  const intl = useIntl();
  const { simulationResult, defaultPoint, disableConvert } =
    UseRewardConvertCard(reward);

  const openHelp = () => {
    // setOpenHelperAbout(true)
  };

  return (
    <div className={props.classes.rewardCard}>
      <ComponentDecoration />

      {simulationResult.isFetching ? (
        <Loader centered />
      ) : simulationResult.isSuccess ? (
        <PointPriceRatio
          points={simulationResult.data?.max_points_usable}
          pointsPrice={simulationResult.data?.order_suggested?.monetary_value}
        />
      ) : (
        <PointPriceRatio points={defaultPoint} pointsPrice={0} />
      )}

      {cardDetails && (
        <div className={props.classes.details}>
          {cardDetails.map(({ Icon, label }, index) => {
            return (
              <div key={index} className={props.classes.detail}>
                <div className={props.classes.iconDetail}>
                  <Icon />
                </div>
                <Typography variant={'body1'}>{label}</Typography>
              </div>
            );
          })}
        </div>
      )}

      <div className={props.classes.actions}>
        <Button
          color={'primary'}
          variant={'contained'}
          size={'large'}
          onClick={() => convert(reward)}
          disabled={disableConvert}
        >
          {intl.formatMessage({
            id: 'spider.convertPoints.home.rewardsCard.convertAction',
          })}
        </Button>
        <Link className={'bold'} onClick={openHelp}>
          {intl.formatMessage({
            id: 'spider.convertPoints.home.rewardsCard.help',
          })}
        </Link>
      </div>
    </div>
  );
};

export default withStyles(styles)(RewardConvertCard);
