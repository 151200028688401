import React, { useMemo } from 'react';
import { withStyles } from '@mui/styles';
import { ReactComponent as PointCup } from '@spider:src/assets/img/points-cup.svg';
import { ReactComponent as InfoCircle } from '@spider:src/assets/img/homeParticipantsConvert/info-circle.svg';
import { ReactComponent as WarningIcon } from '@spider:src/assets/img/warning-icon.svg';
import { ReactComponent as LampOnIcon } from '@spider:src/assets/img/lamp-on-icon.svg';
import BackgroundPriceConverter from '@spider:src/assets/img/homeParticipantsConvert/background-price-converter.png';
import { neutralColors, systemColors } from '@spider:src/themes';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { useIntl } from 'react-intl';
import Formsy from 'formsy-react';
import { Button } from '@spider:src/components';
import TextField from '@spider:src/components/TextField/TextField';
import useSessionStorageSliceForm from '@spider:src/hooks/UseSessionStorageSliceForm';
import {
  clearConvertPointDataStep,
  setConvertPointDataStep,
} from '@spider:src/features/convertPoint/stepperData/slices';
import { useAuth } from '@src/auth';
import { useSelector } from 'react-redux';
import useCommandReward from '@spider:src/hooks/CommandReward/UseCommandReward';
import {
  PointCommandIds,
  RewardAllocationConfigurationCodes,
} from '@spider:src/enums';
import { Loader } from '@src/components';
import useConvertPointsHelper from '@spider:src/hooks/CommandReward/UseConvertPointsHelper';
import { Alert } from '@spider:src/components/Alert';
import { SystemCode } from '@spider:src/enums/systemCode';
import UseRewardConfigAccess from '@spider:src/hooks/UseRewardConfigAccess';
import { CloseButtonIcon } from '@spider:src/components/CloseButtonIcon';

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1.5rem',
  },
  rootTitle: {
    display: 'flex',
    flexDirection: 'row',
    gap: '0.5rem',
  },
  convertZone: {
    display: 'flex',
    padding: '2.5rem 4rem',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '1rem',
    alignSelf: 'stretch',
    borderRadius: '1rem',
    background: neutralColors.neutralColdWhite,
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    gap: '1rem',
    justifyContent: 'center',
    alignItems: 'center',
  },
  informationChip: {
    display: 'flex',
    padding: '0.25rem 0.5rem',
    alignItems: 'center',
    gap: '0.5rem',
    borderRadius: '3rem',
    border: `1px solid ${systemColors.infoRegular}`,
    color: systemColors.infoRegular,
  },
  priceResultZone: {
    display: 'flex',
    padding: '2rem 1rem',
    marginTop: '-1rem',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '2rem',
    alignSelf: 'stretch',
    borderRadius: '1rem',
    background: `url(${BackgroundPriceConverter})`,
    backgroundSize: 'cover',
    width: '95%',
    margin: 'auto',
  },
  priceDetails: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '0.5rem',
    alignSelf: 'stretch',
  },
};

const PointsStep = ({ nextStep, ...props }) => {
  const { hierarchyNodeUser } = useAuth();
  const intl = useIntl();

  const stepperData = useSelector(state => state.getStepperDataKey);
  const simulationRewardAllocation = useSelector(
    state => state.simulateRewardsAllocationsKey,
  );
  const { Card } = useCommandReward(stepperData.commandId);
  const { isDataSavable, minimumPoints, maximumPoints } =
    useConvertPointsHelper();
  const { getConfigByCode } = UseRewardConfigAccess({
    reward: stepperData.commandId,
  });

  const [open, setOpen] = React.useState(false);
  const closeDialog = () => {
    setOpen(false);
  };

  const { formData, handleFormChange } = useSessionStorageSliceForm({
    key: 'convertPointsStep',
    sliceKey: 'getStepperDataKey',
    sliceProp: 'convertPointsDataStep',
    setClear: clearConvertPointDataStep,
    sliceSetter: setConvertPointDataStep,
    isDataSavable,
    automaticSave: true,
  });

  const isMultipleCard = useMemo(() => {
    return (
      simulationRewardAllocation.success &&
      !simulationRewardAllocation.loading &&
      simulationRewardAllocation.simulation.order_suggested.items &&
      simulationRewardAllocation.simulation.order_suggested.items.length > 1
    );
  }, [simulationRewardAllocation]);

  const maximumPerCard = useMemo(() => {
    const configuration = getConfigByCode(
      RewardAllocationConfigurationCodes.MAXIMUM_AMOUNT,
    );
    return configuration ? configuration.value : null;
  }, [stepperData.commandId]);

  const helperTextValue = useMemo(
    () =>
      stepperData.commandId &&
      stepperData.commandId.uuid === PointCommandIds.CKU
        ? intl
            .formatMessage({
              id: 'spider.convertPoints.stepper.pointStep.helperTextFieldSpe',
            })
            .format(
              parseInt(minimumPoints).toFormatNumber(false),
              parseInt(maximumPoints).toFormatNumber(false),
            )
        : intl
            .formatMessage({
              id: 'spider.convertPoints.stepper.pointStep.helperTextField',
            })
            .format(minimumPoints),
    [stepperData.commandId, minimumPoints, maximumPoints, formData.points],
  );

  const pointValueChange = e => {
    handleFormChange(e);
  };

  const validPointsStep = () => {
    nextStep();
  };

  return (
    <Formsy
      name={'point-converter-form'}
      onValidSubmit={validPointsStep}
      className={props.classes.root}
    >
      <div className={props.classes.rootTitle}>
        <PointCup
          fill={neutralColors.neutral900}
          style={{ width: '1.5rem', height: '1.5rem' }}
        />
        <Typography variant={'h3'} component={'h3'}>
          {intl.formatMessage({
            id: 'spider.convertPoints.stepper.pointStep.title',
          })}
        </Typography>
      </div>

      {stepperData.commandId &&
        stepperData.commandId.uuid === PointCommandIds.CKU &&
        hierarchyNodeUser.cku &&
        hierarchyNodeUser.cku.last_active_cku !== null && (
          <Alert
            type={SystemCode.WARNING}
            Icon={() => <WarningIcon />}
            title={intl.formatMessage({
              id: 'spider.convertPoints.stepper.pointStep.rechargeAlertTitle',
            })}
            titleVariant={'subtitle1'}
            titleComponent={'span'}
            onClick={() => setOpen(true)}
            Content={() => (
              <div>
                <Typography variant={'body2'} style={{ lineHeight: '1.5rem' }}>
                  {intl.formatMessage({
                    id: 'spider.convertPoints.stepper.pointStep.rechargeAlertMessageOne',
                  })}
                </Typography>
                <Typography variant={'body2'} style={{ lineHeight: '1.5rem' }}>
                  {intl.formatMessage({
                    id: 'spider.convertPoints.stepper.pointStep.rechargeAlertMessageTwo',
                  })}
                </Typography>
              </div>
            )}
          />
        )}

      <div>
        <div className={props.classes.convertZone}>
          <Typography variant={'subtitle1'}>
            {intl.formatMessage({
              id: 'spider.convertPoints.stepper.pointStep.convertTitle',
            })}
          </Typography>
          <TextField
            name={'points'}
            type={'number'}
            value={formData.points}
            label={intl.formatMessage({
              id: 'spider.convertPoints.stepper.pointStep.textFieldLabel',
            })}
            variant={'outlined'}
            required
            style={{ width: '60%' }}
            helperText={helperTextValue}
            validations={{
              isInt: true,
              isMoreThanOrEquals: minimumPoints,
              isLessThanOrEquals: maximumPoints,
            }}
            validationErrors={{
              isDefaultRequiredValue: intl.formatMessage({
                id: 'common.form.required_error',
              }),
              isInt: intl.formatMessage({ id: 'common.form.is_int_error' }),
              isMoreThanOrEquals: helperTextValue,
              isLessThanOrEquals: helperTextValue,
            }}
            onChange={pointValueChange}
          />
          <div className={props.classes.informationChip}>
            <InfoCircle fill={systemColors.infoRegular} />
            <Typography
              variant={'subtitle1'}
              style={{ fontSize: '0.625rem', fontWeight: 'bold' }}
            >
              {intl
                .formatMessage({
                  id: 'spider.convertPoints.stepper.pointStep.informationDetails',
                })
                .format(
                  hierarchyNodeUser.point_balance.available - formData.points,
                )}
            </Typography>
          </div>
        </div>
        <div className={props.classes.priceResultZone}>
          <div className={props.classes.priceDetails}>
            {stepperData.commandId && isMultipleCard && maximumPerCard && (
              <Alert
                Icon={() => <LampOnIcon />}
                style={{
                  backgroundColor: `${neutralColors.neutralWhite}66`,
                  color: neutralColors.neutral900,
                }}
                title={intl
                  .formatMessage({
                    id: 'spider.convertPoints.stepper.pointStep.multipleCardAlertTitle',
                  })
                  .format(
                    stepperData.commandId.name,
                    maximumPerCard.toFormatNumber(),
                  )}
                titleVariant={'body2'}
                titleComponent={'span'}
                titleStyle={{
                  fontWeight: 'normal',
                  color: neutralColors.neutral900,
                }}
              />
            )}
            <Typography variant={'body1'}>
              {intl.formatMessage({
                id: 'spider.convertPoints.stepper.pointStep.priceReceivedLabel',
              })}
            </Typography>
            {isMultipleCard && (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  textAlign: 'center',
                  gap: '0.5rem',
                }}
              >
                <div>
                  {simulationRewardAllocation.simulation.order_suggested.items.map(
                    (detail, index) => (
                      <span key={index}>
                        <Typography variant={'body2'} component={'span'}>
                          {intl
                            .formatMessage({
                              id: 'spider.convertPoints.stepper.pointStep.priceDetailsCard',
                            })
                            .format(detail.quantity)}
                        </Typography>
                        <Typography
                          variant={'body2'}
                          component={'span'}
                          style={{ fontWeight: 'bold' }}
                        >
                          {intl
                            .formatMessage({
                              id: 'spider.convertPoints.stepper.pointStep.priceDetailsMonetaryValue',
                            })
                            .format(detail.monetary_value.toFormatNumber())}
                        </Typography>
                        {simulationRewardAllocation.simulation.order_suggested
                          .items[index + 1] && (
                          <Typography variant={'body2'} component={'span'}>
                            {intl.formatMessage({
                              id: 'spider.convertPoints.stepper.pointStep.priceDetailsPlus',
                            })}
                          </Typography>
                        )}
                      </span>
                    ),
                  )}
                </div>
                <Typography variant={'body1'}>
                  {intl.formatMessage({
                    id: 'spider.convertPoints.stepper.pointStep.priceReceivedLabelTotal',
                  })}
                </Typography>
              </div>
            )}
            <div>
              {simulationRewardAllocation.loading && <Loader centered />}
              {simulationRewardAllocation.success &&
                !simulationRewardAllocation.loading && (
                  <Typography
                    variant={'h1'}
                    component={'h1'}
                    style={{ fontSize: '3.5rem', fontWeight: 'bold' }}
                  >
                    {intl
                      .formatMessage({
                        id: 'spider.convertPoints.stepper.pointStep.price',
                      })
                      .format(
                        simulationRewardAllocation.simulation.order_suggested.monetary_value.toFormatNumber(),
                      )}
                  </Typography>
                )}
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: '0.25rem',
                }}
              >
                <Typography variant={'body1'} style={{ fontSize: '0.75rem' }}>
                  {intl.formatMessage({
                    id: 'spider.convertPoints.stepper.pointStep.includeFeesInfo',
                  })}
                </Typography>
                <Tooltip
                  title={intl.formatMessage({
                    id: 'spider.convertPoints.stepper.pointStep.includeFeesInfoTooltip',
                  })}
                  placement='top'
                  arrow
                >
                  <InfoCircle fill={neutralColors.neutral900} />
                </Tooltip>
              </div>
            </div>
            <Card />
          </div>
        </div>
      </div>

      <Dialog open={open} onClose={closeDialog}>
        <DialogTitle disableTypography style={{ alignItems: 'baseline' }}>
          <div
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'flex-end',
            }}
          >
            <CloseButtonIcon onClick={closeDialog} />
          </div>
        </DialogTitle>

        <DialogContent
          style={{ display: 'flex', flexDirection: 'column', gap: '2rem' }}
        >
          <div>
            <Typography
              variant={'h1'}
              component={'h1'}
              className={'underline-center'}
              style={{ width: '100%', textAlign: 'center' }}
            >
              {intl.formatMessage({
                id: 'spider.convertPoints.stepper.pointStep.lostCardDialog.title',
              })}
            </Typography>
          </div>

          <div>
            <Typography variant={'subtitle1'} style={{ textAlign: 'center' }}>
              {intl.formatMessage({
                id: 'spider.convertPoints.stepper.pointStep.lostCardDialog.blockMessage',
              })}
            </Typography>
            <ul style={{ margin: '0' }}>
              <li>
                <Typography
                  variant={'subtitle1'}
                  style={{ textAlign: 'center' }}
                >
                  {intl.formatMessage({
                    id: 'spider.convertPoints.stepper.pointStep.lostCardDialog.blockFromClientSpace',
                  })}
                </Typography>
              </li>
              <li>
                <Typography
                  variant={'subtitle1'}
                  style={{ textAlign: 'center' }}
                >
                  {intl.formatMessage({
                    id: 'spider.convertPoints.stepper.pointStep.lostCardDialog.blockByPhone',
                  })}
                </Typography>
              </li>
            </ul>
          </div>
        </DialogContent>

        <DialogActions>
          <Button
            size={'small'}
            color={'primary'}
            variant={'contained'}
            onClick={closeDialog}
          >
            {intl.formatMessage({
              id: 'spider.convertPoints.stepper.pointBalanceDialog.closeAction',
            })}
          </Button>
        </DialogActions>
      </Dialog>

      <div className={props.classes.actions}>
        <Button
          color={'primary'}
          variant={'contained'}
          size={'small'}
          type={'submit'}
          disabled={!isDataSavable(formData)}
        >
          {intl.formatMessage({ id: 'spider.common.submit' })}
        </Button>
      </div>
    </Formsy>
  );
};

export default withStyles(styles)(PointsStep);
