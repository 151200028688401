import React, { useEffect, useState } from 'react';
import { useClearCache } from 'react-clear-cache';
import { ErrorBoundary } from 'react-error-boundary';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import { CssBaseline, ThemeProvider } from '@material-ui/core';
import { cssBaselineStyles } from './styles';
import { AppBar, Footer, SideBar } from './components';
import { ErrorFallback } from '../../../scenes';
import { useTheme } from '@src/distributors';
import { PreventLeavingWithoutSavingDialog } from '../../PreventLeavingWithoutSavingDialog';
import UseHistoryBlock from '@spider:src/hooks/UseHistoryBlock';
import { clearFailure, mainKeyStart } from '@spider:src/features/main/slices';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth } from '@src/auth';
import { SystemFileCodes, UserRoles } from '@spider:src/enums';
import { DocumentApproval } from '../../DocumentApproval';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import { useIntl } from 'react-intl';
import useApprovalHelper from '@spider:src/hooks/UseApprovalHelper';
import OperationDialog from './components/OperationDialog';
import PointConverterShortcut from './components/PointConverterShortcut';

const UserLayout = ({ component: Component, authorizedRoles, ...props }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const history = useHistory();

  const mainState = useSelector(state => state.mainKey);
  const [config, setConfig] = useState(mainState.config);

  const theme = useTheme(cssBaselineStyles, config);

  const [refreshMain, setRefreshMain] = useState(false);

  const { emptyCacheStorage } = useClearCache();
  const { contract } = useParams();
  const { hierarchyNodeUser } = useAuth();
  const {
    openPreventLeavingWithoutSavingDialog,
    continueNavigation,
    submitModification,
  } = UseHistoryBlock();
  const {
    closeDocumentApproval,
    documentToApprove,
    showMainContent,
    approveDocumentDialog,
  } = useApprovalHelper(SystemFileCodes.REGLEMENT, () => {
    setRefreshMain(!refreshMain);
  });

  const isMobile = isWidthDown('xs', props.width);

  useEffect(() => {
    if (contract) {
      dispatch(mainKeyStart({ teamGroupId: contract }));
    }
  }, [contract, hierarchyNodeUser, refreshMain]);

  useEffect(() => {
    if (mainState.error) {
      dispatch(clearFailure());
      history.push('/nodes');
    }
    if (mainState.config) {
      setConfig(mainState.config);
    }
  }, [mainState]);

  const handleError = (error, info) => {
    console.error('Error caught by ErrorBoundary: ', error, info);
  };

  const handleReset = async () => {
    await emptyCacheStorage();
    history.push('/');
  };

  if (!authorizedRoles.includes(hierarchyNodeUser.role)) {
    return <Redirect to={`/nodes/${contract}/home`} />;
  }

  return (
    <>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className='sidebar-root'>
          <AppBar />
          <div className='sidebar'>
            {!isMobile && showMainContent && (
              <div className={'sidebar-content'}>
                <OperationDialog />
                {hierarchyNodeUser &&
                  hierarchyNodeUser.role === UserRoles.COLLABORATOR && (
                    <PointConverterShortcut />
                  )}
                <SideBar />
              </div>
            )}

            <div className='ft-wrapper'>
              <main style={{ justifyContent: 'start', padding: '0.5rem' }}>
                {(showMainContent ||
                  (!documentToApprove && !showMainContent)) && (
                  <ErrorBoundary
                    FallbackComponent={ErrorFallback}
                    onReset={handleReset}
                    onError={handleError}
                  >
                    <Component />
                  </ErrorBoundary>
                )}

                <DocumentApproval
                  open={approveDocumentDialog}
                  onDialogClose={closeDocumentApproval}
                  title={intl.formatMessage({
                    id: 'spider.documentApproval.title',
                  })}
                  checkBoxValidationLabel={intl.formatMessage({
                    id: 'spider.documentApproval.acceptRulesLabel',
                  })}
                  checkBoxValidationErrorLabel={intl.formatMessage({
                    id: 'spider.documentApproval.errorLabel',
                  })}
                  document={documentToApprove}
                />

                <PreventLeavingWithoutSavingDialog
                  open={openPreventLeavingWithoutSavingDialog}
                  onDialogClose={continueNavigation}
                  leaveWithoutRegister={continueNavigation}
                  submitModifications={submitModification}
                />
              </main>
              <Footer />
            </div>
          </div>
        </div>
      </ThemeProvider>
    </>
  );
};

export default withWidth()(UserLayout);
