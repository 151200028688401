import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { RowsPerPageSelector } from '../RowsPerPageSelector';
import UsePagination from '../../hooks/UsePagination';
import { formatDate } from '../../../helpers/DateHelper';
import { CircularProgress, Drawer, Pagination } from '@mui/material';
import { systemColors } from '../../themes';
import OrderStatus from '../OrderStatus';
import { Button } from '@spider:src/components';
import { ArrowRight } from 'iconsax-react';
import { ProductType } from './components/ProductType';
import { useDisclosure } from '@src/Spider/hooks/useDisclosure';
import { OrderDetails } from './components/OrderDetails';
import { useLazyGetHierarchyNodeOrdersListQuery } from '@src/data/api/endpoints/hierarchyNodes';

const styles = {
  stickyHeader: {
    position: 'sticky',
    right: 0,
    zIndex: 3,
  },
  stickyColumn: {
    position: 'sticky',
    right: 0,
    background: 'white',
    zIndex: 2,
  },
};
/** @typedef {import('@async-calls/orders').Order} Order */
/** @param {{queryParams: import("@async-calls/orders").OrdersListParams}} */
export const OrdersList = ({ queryParams }) => {
  const { open, onOpen, onClose } = useDisclosure();
  // Unused now, waiting for backend to be plugged
  const [selectedOrderUuid, setSelectedOrderUuid] = useState('');
  const intl = useIntl();
  const [fetch, { data, isFetching, error }] =
    useLazyGetHierarchyNodeOrdersListQuery();
  const {
    pagination,
    handleChangePage,
    handleChangeRowsPerPage,
    paginationCount,
  } = UsePagination({
    dataLength: data?.count ? data?.count : 0,
  });

  useEffect(() => {
    fetch({
      ...queryParams,
      include_items: true,
      page: pagination.page,
      page_size: pagination.rowsPerPage,
    });
  }, [pagination.page, pagination.rowsPerPage]);

  const columns = ['date', 'product', 'ref', 'total', 'status'].map(el => ({
    field: el,
    label: intl.formatMessage({
      id: `spider.orders.tabs.list.table.${el}`,
    }),
  }));

  const onOpenDrawer = order => {
    onOpen();
    setSelectedOrderUuid(order.uuid);
  };

  const onCloseDrawer = () => {
    onClose();
    setSelectedOrderUuid('');
  };

  return (
    <>
      <Box display='flex' flexDirection='column'>
        <Box display='flex' justifyContent='space-between'>
          <Box>
            {isFetching && <CircularProgress color='primary' size='1.2rem' />}
            {Boolean(error) && (
              <Typography style={{ color: systemColors.errorRegular }}>
                {error?.message}
              </Typography>
            )}
          </Box>
          <RowsPerPageSelector
            rowsPerPage={pagination.rowsPerPage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Box>
        <TableContainer component={Paper} style={{ maxWidth: '100%' }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {columns.map(column => {
                  return (
                    <TableCell
                      key={column.field}
                      style={{ wordBreak: 'keep-all', whiteSpace: 'nowrap' }}
                    >
                      {column.label}
                    </TableCell>
                  );
                })}
                <TableCell
                  style={{
                    ...styles.stickyHeader,
                    wordBreak: 'keep-all',
                    whiteSpace: 'nowrap',
                  }}
                ></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Boolean(data?.results.length) &&
                data.results.map(
                  /** @param {Order} order */ order => (
                    <TableRow key={order.uuid}>
                      <TableCell>
                        <Typography>
                          {formatDate(order.creation_date).replaceAll('/', '.')}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <ProductType
                          product={
                            order.items?.[0]?.reward_allocation?.reward?.uuid
                          }
                        />
                      </TableCell>
                      <TableCell>
                        <Box>
                          <Typography>
                            {order.items?.[0]?.reward_allocation?.reward?.uuid}
                          </Typography>
                          <Typography style={{ fontSize: '0.75rem' }}>
                            {order.number}
                          </Typography>
                        </Box>
                      </TableCell>
                      <TableCell>
                        <Box>
                          <Typography>
                            {order.monetary_value.toFormatNumber()}
                          </Typography>
                          <Typography style={{ fontSize: '0.75rem' }}>
                            {intl.formatMessage(
                              { id: 'spider.orders.details.points' },
                              { points: order.points },
                            )}
                          </Typography>
                        </Box>
                      </TableCell>
                      <TableCell>
                        <OrderStatus status={order.status} />
                      </TableCell>
                      <TableCell style={styles.stickyColumn}>
                        <Button
                          variant='contained'
                          color='primary'
                          size='small'
                          onClick={() => onOpenDrawer(order)}
                        >
                          <ArrowRight />
                        </Button>
                      </TableCell>
                    </TableRow>
                  ),
                )}
            </TableBody>
          </Table>
        </TableContainer>
        <Box display='flex' width='100%' justifyContent='center' mt='1.5rem'>
          <Pagination
            count={paginationCount}
            onChange={handleChangePage}
            variant='outlined'
          />
        </Box>
      </Box>
      <Drawer anchor='right' open={open} onClose={onCloseDrawer}>
        <OrderDetails uuid={'4ff1a735-91e4-4ffd-b2fa-a94644b80914'} />
      </Drawer>
    </>
  );
};
