import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { CircularProgress } from '@material-ui/core';

import { getQueryParam } from '@src/helpers/UrlHelper';
import {
  signInRedirectCallback,
  signOutSilent,
  signUpRedirectCallback,
} from './oidc';

export const AuthCallback = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    async function handleCallback() {
      let redirectPath = getQueryParam(location.search, 'redirect');
      const mode = getQueryParam(location.search, 'mode');

      if (mode && mode !== 'register') {
        console.error('Invalid mode');
        history.push('/404');
        return;
      }

      if (mode === 'register') {
        await signUpRedirectCallback();

        const key = getQueryParam(location.search, 'key');
        const encodedKey = encodeURIComponent(key);
        const node = getQueryParam(location.search, 'node');

        if (!node || !key) {
          console.error('Invalid node or key for registration');
          signOutSilent(dispatch);
          history.push('/404');
          return;
        }

        redirectPath = `/nodes/${node}/finalize-activation?key=${encodedKey}`;
      } else {
        await signInRedirectCallback();
      }

      const isValidRedirectPath = path => {
        return (
          path.startsWith('/') &&
          !path.startsWith('//') &&
          !path.includes('://')
        );
      };

      if (redirectPath && isValidRedirectPath(redirectPath)) {
        history.push(redirectPath);
      } else {
        history.push('/nodes');
      }
    }

    handleCallback();
  }, [history, location.search]);

  return (
    <>
      <div className={'ft-content'}>
        <CircularProgress />
      </div>
    </>
  );
};
