import React, { useEffect } from 'react';
import { Box, Typography, IconButton } from '@material-ui/core';
import { withStyles } from '@mui/styles';
import { useIntl } from 'react-intl';
import { Close as CloseIcon } from '@mui/icons-material';
import { Button } from '@spider:src/components';
import { formatDate } from '@src/helpers/DateHelper';
import { useLazyGetOrderDetailsQuery } from '@src/data/api/endpoints/orders';
import { CircularProgress } from '@mui/material';
import { systemColors } from '@src/Spider';
import { Link } from 'react-router-dom';
import OrderStatus from '../../OrderStatus';
import { InfoCircle, Truck } from 'iconsax-react';
import { GeoLocation } from '../../icons/GeoLocation';

const styles = {
  loading: {
    width: '100%',
    minWidth: '10rem',
    height: '10rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  container: {
    width: '100%',
    padding: '1.5rem',
    backgroundColor: 'white',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '2rem',
  },
  headerTitle: {
    fontWeight: 'bold',
  },
  cancelButton: {
    marginBottom: '2rem',
    width: '100%',
    color: `${systemColors.errorRegular} !important`,
    borderColor: `${systemColors.errorRegular} !important`,
    border: '2px solid !important',
    borderRadius: '0.5rem !important',
  },
  infoBox: {
    backgroundColor: '#EFF6FF',
    padding: '1.5rem',
    borderRadius: '0.5rem',
    marginBottom: '1.5rem',
  },
  infoSection: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '1rem',
    marginBottom: '1rem',
  },
  sectionIcon: {
    marginTop: '0.25rem',
  },
  sectionTitle: {
    fontWeight: '700',
    fontSize: '1.5rem',
    marginBottom: '1rem',
  },
  fieldWrapper: {
    display: 'flex',
    gap: '0.5rem',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  itemValue: {
    fontWeight: '700!important',
    fontSize: '1rem!important',
  },
  addressName: {
    fontWeight: 500,
    marginBottom: '0.5rem',
  },
  addressLine: {
    marginBottom: '0.25rem',
  },
  trackingButton: {
    padding: 0,
    minWidth: 0,
  },
  titleSection: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    marginBottom: '1rem',
    gap: '0.5rem',
  },
  iconTitle: {
    display: 'flex',
    gap: '0.5rem',
  },
};

/**
 * @typedef {import('@src/mock/datasets/ordersList').OrderItem} OrderItem
 * @param {{
 *   classes: any,
 *   uuid: string,
 *   onClose: () => void
 * }} props
 */
const Component = ({ classes, uuid, onClose }) => {
  const intl = useIntl();
  const [fetch, { data, isFetching }] = useLazyGetOrderDetailsQuery(uuid);

  useEffect(() => {
    if (uuid) {
      fetch({ id: uuid });
    }
  }, [uuid, fetch]);

  if (!uuid) return null;

  if (isFetching || !data)
    return (
      <Box className={classes.loading}>
        <CircularProgress />
      </Box>
    );

  return (
    <Box className={classes.container}>
      <Box className={classes.header}>
        <IconButton onClick={onClose} size='small'>
          <CloseIcon />
        </IconButton>
      </Box>
      <Box className={classes.titleSection}>
        <Typography variant='h6' className={classes.headerTitle}>
          {intl.formatMessage({ id: 'spider.orders.details.title' })}
        </Typography>
        <Button
          variant='outlined'
          className={classes.cancelButton}
          onClick={() => {
            console.log('Cancel order:', data.uuid);
          }}
        >
          {intl.formatMessage({ id: 'spider.orders.details.cancelOrder' })}
        </Button>
      </Box>
      <Box className={classes.infoBox}>
        <Box className={classes.infoSection}>
          <Box className={classes.iconTitle}>
            <InfoCircle />
            <Typography variant='h3'>
              {intl.formatMessage({
                id: 'spider.orders.details.generalInfo',
              })}
            </Typography>
          </Box>
          <Box className={classes.fieldWrapper}>
            <Typography>
              {data.items?.[0]?.reward_allocation?.reward?.name}
            </Typography>
            <OrderStatus status={data.status} />
          </Box>
          <Box>
            <Typography className={classes.itemValue}>
              {data.items[0]?.reward_allocation?.reward?.uuid}
            </Typography>
            <Typography variant='body2'>
              {intl.formatMessage({ id: 'spider.orders.details.orderRef' })}
            </Typography>
          </Box>
          <Box>
            <Typography className={classes.itemValue}>{data.number}</Typography>
            <Typography variant='body2'>
              {intl.formatMessage({
                id: 'spider.orders.details.orderNumber',
              })}
            </Typography>
          </Box>
          <Box>
            <Typography className={classes.itemValue}>
              {`${data.points} points (${data.monetary_value}€)`}
            </Typography>
            <Typography variant='body2'>
              {intl.formatMessage({ id: 'spider.orders.details.amount' })}
            </Typography>
          </Box>
          <Box>
            <Typography className={classes.itemValue}>
              {formatDate(data.creation_date)}
            </Typography>
            <Typography variant='body2'>
              {intl.formatMessage({ id: 'spider.orders.details.orderDate' })}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box className={classes.infoBox}>
        <Box className={classes.infoSection}>
          <Box className={classes.iconTitle}>
            <GeoLocation />
            <Typography variant='h3'>
              {intl.formatMessage({ id: 'spider.orders.details.deliveryInfo' })}
            </Typography>
          </Box>
          <Typography className={classes.addressName}>MOCKED</Typography>
          <Typography className={classes.addressLine}>MOCKED</Typography>
          <Typography>MOCKED</Typography>
        </Box>
      </Box>
      <Box className={classes.infoBox}>
        <Box className={classes.infoSection}>
          <Box className={classes.iconTitle}>
            <Truck />
            <Typography variant='h3'>
              {intl.formatMessage({
                id: 'spider.orders.details.deliveryTracking',
              })}
            </Typography>
          </Box>
          <Typography>
            {intl.formatMessage(
              { id: 'spider.orders.details.here' },
              { ici: <Link to='#'>ici</Link> },
            )}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

const OrderDetails = withStyles(styles)(Component);
export { OrderDetails };
