import React, { useState } from 'react';
import { withStyles } from '@mui/styles';
import Formsy from 'formsy-react';
import { Button } from '@spider:src/components';
import { useIntl } from 'react-intl';
import { neutralColors, systemColors } from '@spider:src/themes';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Typography,
} from '@material-ui/core';
import { ReactComponent as PointDeclaration } from '@spider:src/assets/img/points-declaration.svg';
import TextField from '@spider:src/components/TextField/TextField';
import useSessionStorageSliceForm from '@spider:src/hooks/UseSessionStorageSliceForm';
import {
  clearDeclarationDataStep,
  setDeclarationDataStep,
} from '@spider:src/features/convertPoint/stepperData/slices';
import { createTheme } from '@material-ui/core/styles';
import { useAuth } from '@src/auth';

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1.5rem',
  },
  rootTitle: {
    display: 'flex',
    flexDirection: 'row',
    gap: '0.5rem',
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    gap: '1rem',
    justifyContent: 'center',
    alignItems: 'center',
  },
  formFields: {
    display: 'grid',
    gap: '1rem',
    gridTemplateColumns: 'repeat(1, 1fr)',
    [createTheme().breakpoints.up('sm')]: {
      gridTemplateColumns: 'repeat(2, 1fr)',
    },
  },
  informationCard: {
    display: 'flex',
    padding: '1rem 1rem 1rem 1rem',
    flexDirection: 'column',
    gap: '0.5rem',
    justifyContent: 'center',
    borderRadius: '1rem',
    overflow: 'clip',
    position: 'relative',
    backgroundColor: neutralColors.neutralColdWhite,
  },
  block: {
    display: 'flex',
    gap: '1rem',
    flexDirection: 'column',
  },
};

const DeclarationStep = ({ nextStep, previousStep, ...props }) => {
  const intl = useIntl();
  const { hierarchyNodeUser } = useAuth();

  const [isHonoredAttestation, setIsHonoredAttestation] = useState(false);
  const [formError, setFormError] = useState(false);
  const [isSubmitDisable, setIsValidDisable] = useState(true);

  const originalLastnameMaxLength = 50;
  const birthDepartmentMaxLength = 5;
  const birthCommuneMaxLength = 3;
  const birthCountryMaxLength = 60;
  const nationalityCountryMaxLength = 60;
  const socialSecurityNumberMaxLength = 15;

  const socialRaisonMaxLength = 35;
  const addressMaxLength = 35;
  const zipCodeMaxLength = 5;
  const cityMaxLength = 29;

  const maxDate = new Date();

  const defaultFormData = {
    firstname: hierarchyNodeUser.firstname,
    lastname: hierarchyNodeUser.lastname,
    originalLastname: '',
    email: hierarchyNodeUser.email,

    line1: '',
    line2: '',
    zipCode: '',
    city: '',

    dateOfBirth: '',
    birthDepartment: '',
    birthCommune: '',
    birthCountry: '',
    nationalityCountry: '',
    socialSecurityNumber: '',

    employerSocialRaison: '',
    employerAddress: '',
    employerComplementaryAddress: '',
    employerZipCode: '',
    employerCity: '',
  };

  const { formData, handleFormChange, saveStateData } =
    useSessionStorageSliceForm({
      key: 'declarationStep',
      defaultFormData,
      sliceKey: 'getStepperDataKey',
      sliceProp: 'declarationDataStep',
      setClear: clearDeclarationDataStep,
      sliceSetter: setDeclarationDataStep,
      isDataSavable: () => true,
    });

  const checkApproval = event => {
    setIsHonoredAttestation(event.target.checked);
    if (formError) {
      setFormError(false);
    }
  };

  const validDeclarationStep = () => {
    if (!isHonoredAttestation) {
      setFormError(true);
      return;
    }

    nextStep();
  };

  return (
    <Formsy
      name={'declaration-form'}
      onValidSubmit={validDeclarationStep}
      className={props.classes.root}
      onValid={() => {
        setIsValidDisable(false);
        saveStateData(formData, true);
      }}
      onInvalid={() => setIsValidDisable(true)}
    >
      <div className={props.classes.rootTitle}>
        <PointDeclaration
          fill={neutralColors.neutral900}
          style={{ width: '1.5rem', height: '1.5rem' }}
        />
        <Typography variant={'h3'} component={'h3'}>
          {intl.formatMessage({
            id: 'spider.convertPoints.stepper.declarationStep.title',
          })}
        </Typography>
      </div>

      <Typography variant={'body1'} style={{ fontStyle: 'italic' }}>
        {intl.formatMessage({
          id: 'spider.convertPoints.stepper.declarationStep.subtitle',
        })}
      </Typography>

      <div className={props.classes.informationCard}>
        <Typography
          variant={'subtitle1'}
          style={{ color: systemColors.infoRegular, fontWeight: 'bold' }}
        >
          {intl.formatMessage({
            id: 'spider.convertPoints.stepper.declarationStep.infoLfssTitle',
          })}
        </Typography>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Typography variant={'body2'}>
            {intl.formatMessage({
              id: 'spider.convertPoints.stepper.declarationStep.infoLfssMessageOne',
            })}
          </Typography>
          <Typography variant={'body2'}>
            {intl.formatMessage({
              id: 'spider.convertPoints.stepper.declarationStep.infoLfssMessageTwo',
            })}
          </Typography>
          <Typography variant={'body2'}>
            {intl.formatMessage({
              id: 'spider.convertPoints.stepper.declarationStep.infoLfssMessageThird',
            })}
          </Typography>
        </div>
      </div>

      <div className={props.classes.block}>
        <Typography variant={'h4'} component={'h4'}>
          {intl.formatMessage({
            id: 'spider.convertPoints.stepper.declarationStep.identification.title',
          })}
        </Typography>

        <div className={props.classes.formFields}>
          <TextField
            name={'firstname'}
            value={formData.firstname}
            label={intl.formatMessage({
              id: 'spider.convertPoints.stepper.declarationStep.identification.firstnameField',
            })}
            variant={'outlined'}
            required
            disabled
            validations={{}}
            validationErrors={{
              isDefaultRequiredValue: intl.formatMessage({
                id: 'common.form.required_error',
              }),
            }}
            onChange={handleFormChange}
          />
          <TextField
            name={'lastname'}
            value={formData.lastname}
            label={intl.formatMessage({
              id: 'spider.convertPoints.stepper.declarationStep.identification.lastnameField',
            })}
            variant={'outlined'}
            required
            disabled
            validations={{}}
            validationErrors={{
              isDefaultRequiredValue: intl.formatMessage({
                id: 'common.form.required_error',
              }),
            }}
            onChange={handleFormChange}
          />
          <TextField
            name={'originalLastname'}
            value={formData.originalLastname}
            label={intl.formatMessage({
              id: 'spider.convertPoints.stepper.declarationStep.identification.originalLastnameField',
            })}
            variant={'outlined'}
            validations={{
              maxLength: originalLastnameMaxLength,
            }}
            validationErrors={{
              maxLength: intl
                .formatMessage({
                  id: 'common.form.max_length_custom_error',
                })
                .format(originalLastnameMaxLength),
            }}
            onChange={handleFormChange}
          />
          <TextField
            name={'email'}
            value={formData.email}
            label={intl.formatMessage({
              id: 'spider.convertPoints.stepper.declarationStep.identification.emailField',
            })}
            variant={'outlined'}
            disabled
            required
            validations={{}}
            validationErrors={{
              isDefaultRequiredValue: intl.formatMessage({
                id: 'common.form.required_error',
              }),
            }}
            onChange={handleFormChange}
          />
        </div>
      </div>
      <div className={props.classes.block}>
        <Typography variant={'h4'} component={'h4'}>
          {intl.formatMessage({
            id: 'spider.convertPoints.stepper.declarationStep.personalAddress.title',
          })}
        </Typography>
        <div className={props.classes.formFields}>
          <TextField
            name={'line1'}
            value={formData.line1}
            label={intl.formatMessage({
              id: 'spider.convertPoints.stepper.declarationStep.personalAddress.addressField',
            })}
            variant={'outlined'}
            required
            validations={{
              maxLength: addressMaxLength,
            }}
            validationErrors={{
              isDefaultRequiredValue: intl.formatMessage({
                id: 'common.form.required_error',
              }),
              maxLength: intl
                .formatMessage({
                  id: 'common.form.max_length_custom_error',
                })
                .format(addressMaxLength),
            }}
            onChange={handleFormChange}
          />
          <TextField
            name={'line2'}
            value={formData.line2}
            label={intl.formatMessage({
              id: 'spider.convertPoints.stepper.declarationStep.personalAddress.complementaryAddressField',
            })}
            variant={'outlined'}
            validations={{
              maxLength: addressMaxLength,
            }}
            validationErrors={{
              isDefaultRequiredValue: intl.formatMessage({
                id: 'common.form.required_error',
              }),
              maxLength: intl
                .formatMessage({
                  id: 'common.form.max_length_custom_error',
                })
                .format(addressMaxLength),
            }}
            onChange={handleFormChange}
          />
          <TextField
            name={'zipCode'}
            type={'number'}
            value={formData.zipCode}
            label={intl.formatMessage({
              id: 'spider.convertPoints.stepper.declarationStep.personalAddress.zipCodeField',
            })}
            variant={'outlined'}
            required
            validations={{
              isInt: true,
              maxLength: addressMaxLength,
            }}
            validationErrors={{
              isDefaultRequiredValue: intl.formatMessage({
                id: 'common.form.required_error',
              }),
              isInt: intl.formatMessage({
                id: 'common.form.is_int_error',
              }),
              maxLength: intl
                .formatMessage({
                  id: 'common.form.max_length_custom_error',
                })
                .format(zipCodeMaxLength),
            }}
            onChange={handleFormChange}
          />
          <TextField
            name={'city'}
            value={formData.city}
            label={intl.formatMessage({
              id: 'spider.convertPoints.stepper.declarationStep.personalAddress.cityField',
            })}
            variant={'outlined'}
            required
            validations={{
              maxLength: cityMaxLength,
            }}
            validationErrors={{
              isDefaultRequiredValue: intl.formatMessage({
                id: 'common.form.required_error',
              }),
              maxLength: intl
                .formatMessage({
                  id: 'common.form.max_length_custom_error',
                })
                .format(cityMaxLength),
            }}
            onChange={handleFormChange}
          />
        </div>
      </div>
      <div className={props.classes.block}>
        <Typography variant={'h4'} component={'h4'}>
          {intl.formatMessage({
            id: 'spider.convertPoints.stepper.declarationStep.personalInformation.title',
          })}
        </Typography>
        <div className={props.classes.formFields}>
          <TextField
            name={'dateOfBirth'}
            value={formData.dateOfBirth}
            label={intl.formatMessage({
              id: 'spider.convertPoints.stepper.informationStep.personalInformation.dateOfBirthField',
            })}
            variant={'outlined'}
            type='date'
            required
            InputLabelProps={{
              shrink: true,
            }}
            validations={{
              isDateLessThan: maxDate,
            }}
            validationErrors={{
              isDefaultRequiredValue: intl.formatMessage({
                id: 'common.form.required_error',
              }),
              isDateLessThan: intl
                .formatMessage({
                  id: 'common.form.is_date_less_than',
                })
                .format(
                  maxDate.toLocaleString('fr-FR', {
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric',
                  }),
                ),
            }}
            onChange={handleFormChange}
          />
          <TextField
            name={'birthDepartment'}
            type={'number'}
            value={formData.birthDepartment}
            label={intl.formatMessage({
              id: 'spider.convertPoints.stepper.declarationStep.personalInformation.depsOfBirth',
            })}
            required
            variant={'outlined'}
            validations={{
              isInt: true,
              maxLength: birthDepartmentMaxLength,
            }}
            validationErrors={{
              isDefaultRequiredValue: intl.formatMessage({
                id: 'common.form.required_error',
              }),
              isInt: intl.formatMessage({
                id: 'common.form.is_int_error',
              }),
              maxLength: intl
                .formatMessage({
                  id: 'common.form.max_length_custom_error',
                })
                .format(birthDepartmentMaxLength),
            }}
            onChange={handleFormChange}
          />
          <TextField
            name={'birthCommune'}
            value={formData.birthCommune}
            label={intl.formatMessage({
              id: 'spider.convertPoints.stepper.declarationStep.personalInformation.communeOfBirth',
            })}
            variant={'outlined'}
            required
            validations={{
              maxLength: birthCommuneMaxLength,
            }}
            validationErrors={{
              isDefaultRequiredValue: intl.formatMessage({
                id: 'common.form.required_error',
              }),
              maxLength: intl
                .formatMessage({
                  id: 'common.form.max_length_custom_error',
                })
                .format(birthCommuneMaxLength),
            }}
            onChange={handleFormChange}
          />
          <TextField
            name={'birthCountry'}
            value={formData.birthCountry}
            label={intl.formatMessage({
              id: 'spider.convertPoints.stepper.declarationStep.personalInformation.countryOfBirth',
            })}
            variant={'outlined'}
            required
            validations={{
              maxLength: birthCountryMaxLength,
            }}
            validationErrors={{
              isDefaultRequiredValue: intl.formatMessage({
                id: 'common.form.required_error',
              }),
              maxLength: intl
                .formatMessage({
                  id: 'common.form.max_length_custom_error',
                })
                .format(birthCountryMaxLength),
            }}
            onChange={handleFormChange}
          />
          <TextField
            name={'nationalityCountry'}
            value={formData.nationalityCountry}
            label={intl.formatMessage({
              id: 'spider.convertPoints.stepper.declarationStep.personalInformation.nationality',
            })}
            variant={'outlined'}
            required
            validations={{
              maxLength: nationalityCountryMaxLength,
            }}
            validationErrors={{
              isDefaultRequiredValue: intl.formatMessage({
                id: 'common.form.required_error',
              }),
              maxLength: intl
                .formatMessage({
                  id: 'common.form.max_length_custom_error',
                })
                .format(nationalityCountryMaxLength),
            }}
            onChange={handleFormChange}
          />
          <TextField
            name={'socialSecurityNumber'}
            type={'number'}
            value={formData.socialSecurityNumber}
            label={intl.formatMessage({
              id: 'spider.convertPoints.stepper.declarationStep.personalInformation.socialSecurityNumber',
            })}
            variant={'outlined'}
            required
            validations={{
              isInt: true,
              securitySocialSecondRange: formData.dateOfBirth,
              securitySocialThirdRange: formData.dateOfBirth,
              securitySocialFourthRange: formData.birthDepartment,
              hasMoreCharactersThan: socialSecurityNumberMaxLength,
              maxLength: socialSecurityNumberMaxLength,
            }}
            validationErrors={{
              isDefaultRequiredValue: intl.formatMessage({
                id: 'common.form.required_error',
              }),
              isInt: intl.formatMessage({
                id: 'common.form.is_int_error',
              }),
              securitySocialSecondRange: intl.formatMessage({
                id: 'common.form.securitySocialSecondRange',
              }),
              securitySocialThirdRange: intl.formatMessage({
                id: 'common.form.securitySocialThirdRange',
              }),
              securitySocialFourthRange: intl.formatMessage({
                id: 'common.form.securitySocialFourthRange',
              }),
              securitySocialFifthRange: intl.formatMessage({
                id: 'common.form.securitySocialFifthRange',
              }),
              hasMoreCharactersThan: intl
                .formatMessage({
                  id: 'common.form.has_more_characters_than',
                })
                .format(socialSecurityNumberMaxLength),
              maxLength: intl
                .formatMessage({
                  id: 'common.form.max_length_custom_error',
                })
                .format(socialSecurityNumberMaxLength),
            }}
            onChange={handleFormChange}
          />
        </div>
      </div>
      <div className={props.classes.block}>
        <Typography variant={'h4'} component={'h4'}>
          {intl.formatMessage({
            id: 'spider.convertPoints.stepper.declarationStep.employerAddress.title',
          })}
        </Typography>
        <div className={props.classes.formFields}>
          <TextField
            name={'employerSocialRaison'}
            value={formData.employerSocialRaison}
            label={intl.formatMessage({
              id: 'spider.convertPoints.stepper.declarationStep.employerAddress.socialRaison',
            })}
            variant={'outlined'}
            required
            validations={{
              maxLength: socialRaisonMaxLength,
            }}
            validationErrors={{
              isDefaultRequiredValue: intl.formatMessage({
                id: 'common.form.required_error',
              }),
              maxLength: intl
                .formatMessage({
                  id: 'common.form.max_length_custom_error',
                })
                .format(socialRaisonMaxLength),
            }}
            onChange={handleFormChange}
          />
          <TextField
            name={'employerAddress'}
            value={formData.employerAddress}
            label={intl.formatMessage({
              id: 'spider.convertPoints.stepper.declarationStep.employerAddress.addressField',
            })}
            variant={'outlined'}
            required
            validations={{
              maxLength: addressMaxLength,
            }}
            validationErrors={{
              isDefaultRequiredValue: intl.formatMessage({
                id: 'common.form.required_error',
              }),
              maxLength: intl
                .formatMessage({
                  id: 'common.form.max_length_custom_error',
                })
                .format(addressMaxLength),
            }}
            onChange={handleFormChange}
          />
          <TextField
            name={'employerComplementaryAddress'}
            value={formData.employerComplementaryAddress}
            label={intl.formatMessage({
              id: 'spider.convertPoints.stepper.declarationStep.employerAddress.complementaryAddressField',
            })}
            variant={'outlined'}
            validations={{
              maxLength: addressMaxLength,
            }}
            validationErrors={{
              isDefaultRequiredValue: intl.formatMessage({
                id: 'common.form.required_error',
              }),
              maxLength: intl
                .formatMessage({
                  id: 'common.form.max_length_custom_error',
                })
                .format(addressMaxLength),
            }}
            onChange={handleFormChange}
          />
          <TextField
            name={'employerZipCode'}
            type={'number'}
            value={formData.employerZipCode}
            label={intl.formatMessage({
              id: 'spider.convertPoints.stepper.declarationStep.employerAddress.zipCodeField',
            })}
            variant={'outlined'}
            required
            validations={{
              isInt: true,
              maxLength: zipCodeMaxLength,
            }}
            validationErrors={{
              isDefaultRequiredValue: intl.formatMessage({
                id: 'common.form.required_error',
              }),
              isInt: intl.formatMessage({
                id: 'common.form.is_int_error',
              }),
              maxLength: intl
                .formatMessage({
                  id: 'common.form.max_length_custom_error',
                })
                .format(zipCodeMaxLength),
            }}
            onChange={handleFormChange}
          />
          <TextField
            name={'employerCity'}
            value={formData.employerCity}
            label={intl.formatMessage({
              id: 'spider.convertPoints.stepper.declarationStep.employerAddress.cityField',
            })}
            variant={'outlined'}
            required
            validations={{
              maxLength: cityMaxLength,
            }}
            validationErrors={{
              isDefaultRequiredValue: intl.formatMessage({
                id: 'common.form.required_error',
              }),
              maxLength: intl
                .formatMessage({
                  id: 'common.form.max_length_custom_error',
                })
                .format(cityMaxLength),
            }}
            onChange={handleFormChange}
          />
        </div>
      </div>

      <FormControl
        required
        error={formError}
        style={{
          display: 'flex',
          justifyContent: 'left',
          flexDirection: 'column',
        }}
      >
        <FormControlLabel
          control={
            <Checkbox
              checked={isHonoredAttestation}
              className={'root'}
              disableRipple
              checkedIcon={<span className={'icon checkedIcon'} />}
              icon={<span className={'icon'} />}
              inputProps={{ 'aria-label': 'decorative checkbox' }}
              onChange={checkApproval}
              required
            />
          }
          label={intl.formatMessage({
            id: 'spider.convertPoints.stepper.declarationStep.honorAttestation',
          })}
          style={{ margin: 0 }}
        />
        {formError && (
          <FormHelperText>
            {intl.formatMessage({ id: 'spider.documentApproval.errorLabel' })}
          </FormHelperText>
        )}
      </FormControl>

      <div className={props.classes.actions}>
        <Button
          color={'primary'}
          variant={'outlined'}
          size={'large'}
          onClick={previousStep}
        >
          {intl.formatMessage({ id: 'spider.common.back' })}
        </Button>
        <Button
          color={'primary'}
          variant={'contained'}
          size={'large'}
          type={'submit'}
          disabled={isSubmitDisable || !isHonoredAttestation}
        >
          {intl.formatMessage({ id: 'spider.common.submit' })}
        </Button>
      </div>
    </Formsy>
  );
};

export default withStyles(styles)(DeclarationStep);
