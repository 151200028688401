import instance from '../instance';
import '../../../helpers/DateHelper';
import { baseApi } from '@src/store/api-query';
import {
  deleteImage,
  update,
  updateImage,
} from '@async-calls/rewardAllocations';

/**
 * @param {string} id ID du noeud hiérarchique en base de données
 */
const baseUrl = id => `rewards/${id}/`;
const TAG = 'reward';

const baseUrlOld = 'rewards/';

const rewards_old = {
  active(name, categoryId) {
    const hasName = name != null && name !== '';
    var url = `${baseUrlOld}active/`;
    if (hasName || categoryId) url += '?';
    if (hasName) url = `${url}name=${name}`;
    if (hasName && categoryId) url += '&';
    if (categoryId) url += `category=${categoryId}`;
    return instance.get(url);
  },
  create(reward) {
    return instance.post(baseUrlOld, reward);
  },
  exportOrders(
    categoryId,
    teamId,
    collaboratorId,
    periodId,
    validationStart,
    validationEnd,
  ) {
    var url = `${baseUrlOld}export-orders/`;
    var hasParam = false;
    if (
      categoryId ||
      teamId ||
      collaboratorId ||
      periodId ||
      validationStart ||
      validationEnd
    )
      url += '?';
    if (categoryId) {
      url += `category=${categoryId}`;
      hasParam = true;
    }
    if (teamId) {
      if (hasParam) url += '&';
      url += `team=${teamId}`;
      hasParam = true;
    }
    if (collaboratorId) {
      if (hasParam) url += '&';
      url += `collaborator=${collaboratorId}`;
      hasParam = true;
    }
    if (periodId) {
      if (hasParam) url += '&';
      url += `period=${periodId}`;
      hasParam = true;
    }
    if (validationStart) {
      if (hasParam) url += '&';
      url += `validationStart=${validationStart.toUTCJSON2()}`;
      hasParam = true;
    }
    if (validationEnd) {
      if (hasParam) url += '&';
      url += `validationEnd=${validationEnd.toUTCJSON2()}`;
      hasParam = true;
    }
    return instance.get(url);
  },
  get(id) {
    return instance.get(baseUrl(id));
  },
  replaceImage(id, rewardImage) {
    return instance.post(`${baseUrl(id)}image/`, rewardImage);
  },
  update(id, reward) {
    return instance.put(baseUrl(id), reward);
  },
  updateActivation(id, isActive) {
    return instance.patch(baseUrl(id), { isActive });
  },
  list(teamId, distributor, allocatedOnly) {
    const params = new URLSearchParams();
    if (distributor) {
      params.append('distributor', distributor);
    }
    if (allocatedOnly) {
      params.append('allocated_only', 'true');
    }
    params.append('page_size', '4');
    const url = `hierarchy-nodes/${teamId}/${baseUrlOld}`;
    return instance.get(url, { params });
  },
  rewardOrder(hierarchyNodeUserId, form, items, lfss_form) {
    const data = {
      hierarchy_node_user: hierarchyNodeUserId,
      items,
    };

    if (form) {
      data.form = form;
    }
    if (lfss_form) {
      data.lfss_form = lfss_form;
    }

    const url = `reward-orders/`;
    return instance.post(url, data);
  },
};

/**
 * @typedef {Object} RewardListParams
 * @property {string} [hierarchyNodeId] Id du nœud hiérarchique
 * @property {string} [distributor] Distributeur à utiliser
 * @property {boolean} [allocatedOnly] Configuration individuelle de la reward allocation
 */

/**
 * @typedef {Object} OrderRewardParams
 * @property {string} [hierarchyNodeUserId] Id du hierarchy node user qui fait la commande
 * @property {string} [form] Les données de l'utilisateur pour commander
 * @property {Array} [items] Les items à commander
 * @property {Object} [lfss_form] Données du formulaire LFSS
 */

const rewards = {
  /** @param {RewardListParams} queryParams */
  list({ hierarchyNodeId, distributor, allocatedOnly }) {
    const params = new URLSearchParams();
    if (distributor) {
      params.append('distributor', distributor);
    }
    if (allocatedOnly) {
      params.append('allocated_only', 'true');
    }
    params.append('page_size', '4');
    const url = `hierarchy-nodes/${hierarchyNodeId}/${baseUrlOld}`;
    return instance.get(url, { params });
  },
  /** @param {OrderRewardParams} queryParams */
  rewardOrder({ hierarchyNodeUserId, form, items, lfss_form }) {
    const data = {
      hierarchy_node_user: hierarchyNodeUserId,
      items,
    };

    if (form) {
      data.form = form;
    }
    if (lfss_form) {
      data.lfss_form = lfss_form;
    }

    const url = `reward-orders/`;
    return instance.post(url, data);
  },
};

const rewardsQueries = baseApi.injectEndpoints({
  tagTypes: [TAG],
  endpoints: builder => ({
    /** @type {import('@reduxjs/toolkit/query/react').QueryDefinition<RewardListParams, typeof builder.query, 'api-query', {}>}*/
    getRewardsList: builder.query({
      queryFn: (params, _queryApi, _extraOptions, baseQuery) => {
        return baseQuery(() => rewards.list(params));
      },
      providesTags: result =>
        result.results
          ? result.results.map(reward => ({
              type: TAG,
              id: reward?._context?.allocation?.uuid,
            }))
          : [TAG],
    }),
    updateRewardAllocation: builder.mutation({
      queryFn: (params, _queryApi, _extraOptions, baseQuery) => {
        return baseQuery(() => update(params));
      },
      invalidatesTags: (result, error, payload) => [
        { type: TAG, id: payload.id },
      ],
    }),
    /** @type {import('@reduxjs/toolkit/query/react').MutationDefinition<RewardAllocationUpdate, typeof builder.mutation, 'api-query', {}>}*/
    updateRewardAllocationImage: builder.mutation({
      queryFn: (params, _queryApi, _extraOptions, baseQuery) => {
        return baseQuery(() => updateImage(params));
      },
      invalidatesTags: (result, error, payload) => [
        { type: TAG, id: payload.id },
      ],
    }),
    /** @type {import('@reduxjs/toolkit/query/react').MutationDefinition<RewardAllocationUpdate, typeof builder.mutation, 'api-query', {}>}*/
    deleteRewardAllocationImage: builder.mutation({
      queryFn: (params, _queryApi, _extraOptions, baseQuery) => {
        return baseQuery(() => deleteImage(params));
      },
      invalidatesTags: (result, error, payload) => [
        { type: TAG, id: payload.id },
      ],
    }),
    /** @type {import('@reduxjs/toolkit/query/react').MutationDefinition<OrderRewardParams, typeof builder.mutation, 'api-query', {}>}*/
    orderReward: builder.mutation({
      queryFn: (params, _queryApi, _extraOptions, baseQuery) => {
        return baseQuery(() => rewards.rewardOrder(params));
      },
    }),
  }),
});

export const {
  useGetRewardsListQuery,
  useUpdateRewardAllocationMutation,
  useUpdateRewardAllocationImageMutation,
  useDeleteRewardAllocationImageMutation,
  useLazyOrderRewardMutation,
} = rewardsQueries;

export default rewards_old;
