import React from 'react';
import { Box, createTheme } from '@material-ui/core';
import { neutralColors } from '@src/Spider';
import { Typography } from '@mui/material';

/**
 * @typedef {Object} Props
 * @property {string} title
 * @property {import('react').ReactNode} [icon]
 * @property {import('react').ReactNode} [action]
 */

/**
 * @typedef {import('react').PropsWithChildren<Props>} Cardprops
 */

/**
 * @param {Cardprops} props
 * @returns {JSX.Element}
 */
const Card = ({ children, action, title, icon }) => {
  return (
    <Box
      sx={{
        backgroundColor: neutralColors.neutralColdWhite,
        padding: '1rem',
        borderRadius: '1rem',
        display: 'flex',
        flexDirection: 'column',
        gap: '1.5rem',
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Box sx={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
          {icon}
          <Typography fontFamily='inherit' fontWeight='700' fontSize='1.25rem'>
            {title}
          </Typography>
        </Box>
        {action}
      </Box>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'repeat(3, 1fr)',
          [createTheme().breakpoints.down('md')]: {
            gridTemplateColumns: 'repeat(2, 1fr)',
          },
          [createTheme().breakpoints.down('sm')]: {
            gridTemplateColumns: 'repeat(1, 1fr)',
          },
          gap: '1rem',
          gridAutoRows: 'auto',
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

/** @param {{label: string, value: string}} props */
const Item = ({ label, value }) => (
  <Box>
    <Typography fontFamily='inherit' fontWeight='700'>
      {value}
    </Typography>
    <Typography fontFamily='inherit' fontSize='0.75rem'>
      {label}
    </Typography>
  </Box>
);

export const Info = {
  Card,
  Item,
};
