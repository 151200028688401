import React, { useState } from 'react';
import { TextField } from '@spider:components/TextField';
import { useIntl } from 'react-intl';
import { RewardAllocationConfigurationType } from '@src/Spider/enums/rewardAllocationConfigurationType';
import { InputAdornment } from '@material-ui/core';
import { RewardAllocationConfigurationCodes } from '@src/Spider/enums';

const ConfigurationField = ({
  configuration,
  configurationDefaultValue = '',
  onConfigurationChange,
}) => {
  const intl = useIntl();

  const [configurationValue, setConfigurationValue] = useState(
    configurationDefaultValue === ''
      ? configurationDefaultValue
      : configuration.type === RewardAllocationConfigurationType.DECIMAL
        ? configurationDefaultValue.toString().replaceAll('.', ',')
        : configurationDefaultValue,
  );

  const configurationValueChanged = e => {
    e.preventDefault();
    let value = '';
    switch (configuration.type) {
      case RewardAllocationConfigurationType.INTEGER:
        if (e.target.value !== '') {
          value = parseInt(e.target.value);
        }
        break;
      case RewardAllocationConfigurationType.DECIMAL:
        if (/^([\d]*[,.]?[\d]{0,2})$/.test(e.target.value)) {
          value = e.target.value.replaceAll('.', ',');
        }
        break;
      default:
        value = e.target.value;
        break;
    }
    setConfigurationValue(value);
    onConfigurationChange({ code: configuration.code, value });
  };

  const getValidation = () => {
    let validations = {};
    switch (configuration.type) {
      case RewardAllocationConfigurationType.INTEGER:
        validations = {
          isInt: true,
          isPositive: true,
        };
        break;
      case RewardAllocationConfigurationType.DECIMAL:
        validations = {
          isNumeric: true,
          isPositive: true,
        };
        break;
      default:
        break;
    }

    return validations;
  };

  const getValidationErrors = () => {
    let validationsErrors = {};
    switch (configuration.type) {
      case RewardAllocationConfigurationType.INTEGER:
        validationsErrors = {
          isInt: intl.formatMessage({
            id: 'common.form.is_int_error',
          }),
          isPositive: intl.formatMessage({
            id: 'common.form.is_more_than_or_equals_0_error',
          }),
        };
        break;
      case RewardAllocationConfigurationType.DECIMAL:
        validationsErrors = {
          isNumeric: intl.formatMessage({
            id: 'common.form.numeric_error',
          }),
          isPositive: intl.formatMessage({
            id: 'common.form.is_more_than_or_equals_0_error',
          }),
        };
        break;
      default:
        break;
    }

    return {
      isDefaultRequiredValue: intl.formatMessage({
        id: 'common.form.required_error',
      }),
      ...validationsErrors,
    };
  };

  const getType = () => {
    let type;
    switch (configuration.type) {
      case RewardAllocationConfigurationType.INTEGER:
        type = 'number';
        break;
      default:
        type = 'text';
        break;
    }

    return type;
  };

  const getStep = () => {
    let step;
    switch (configuration.type) {
      default:
        step = 'any';
        break;
    }

    return step;
  };

  const StartAdornment = () => {
    let startAdornment;
    const euroSignAdornment = (
      <InputAdornment position='start'>€</InputAdornment>
    );
    switch (configuration.code) {
      case RewardAllocationConfigurationCodes.CARD_MANUFACTURING_FEES:
        startAdornment = euroSignAdornment;
        break;
      case RewardAllocationConfigurationCodes.MAXIMUM_ANNUAL_AMOUNT:
        startAdornment = euroSignAdornment;
        break;
      case RewardAllocationConfigurationCodes.MAXIMUM_BALANCE_AMOUNT:
        startAdornment = euroSignAdornment;
        break;
      case RewardAllocationConfigurationCodes.MINIMUM_CREATION_AMOUNT:
        startAdornment = euroSignAdornment;
        break;
      case RewardAllocationConfigurationCodes.MINIMUM_LOADING_AMOUNT:
        startAdornment = euroSignAdornment;
        break;
      case RewardAllocationConfigurationCodes.DELIVERY_FEES:
        startAdornment = euroSignAdornment;
        break;
      case RewardAllocationConfigurationCodes.MAXIMUM_AMOUNT:
        startAdornment = euroSignAdornment;
        break;
      case RewardAllocationConfigurationCodes.MINIMUM_AMOUNT:
        startAdornment = euroSignAdornment;
        break;
      default:
        startAdornment = null;
        break;
    }

    return startAdornment;
  };

  return (
    <TextField
      name={'configurationField'}
      value={configurationValue}
      label={intl.formatMessage({
        id:
          'spider.hierarchy_node.platform_customization.parameters.rewards.fieldCodes.' +
          configuration.code,
      })}
      variant={'outlined'}
      InputProps={{
        startAdornment: <StartAdornment />,
      }}
      type={getType()}
      required
      inputProps={{
        min: getType() === 'number' ? '0' : undefined,
        step: getStep(),
      }}
      validations={getValidation()}
      validationErrors={getValidationErrors()}
      onChange={configurationValueChanged}
    />
  );
};

export default ConfigurationField;
