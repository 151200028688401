import { tagTypes } from '@src/store/api-tags';
import instance from '../instance';
import { baseApi } from '@src/store/api-query';

const baseUrl = 'hierarchy-node-users/';

const hierarchyNodeUsers = {
  approveSystemFile(userId, fileId) {
    const params = new URLSearchParams();

    params.append('system-file', fileId);

    const url = `${baseUrl}${userId}/approve/`;
    return instance.post(url, {}, { params });
  },
  /**
   * @description Obtient les informations spécifiques à un noeud hiérarchique user
   * @param {ParticipantDetailsParams} queryParams
   */
  details({
    user_id,
    include_address,
    include_cku,
    include_manager,
    include_point_balance,
  }) {
    const params = new URLSearchParams();
    if (include_address) params.append('include_adress', include_address);
    if (include_cku) params.append('include_cku', include_cku);
    if (include_manager) params.append('include_manager', include_manager);
    if (include_point_balance)
      params.append('include_point_balance', include_point_balance);
    return instance.get(`hierarchy-node-users/${user_id}`, { params });
  },
  /**
   * @typedef {{status: import("@spider:src/enums/hierarchyNodeUserStatuses").HierarchyNodeUserStatusesType}} UpdateHierarchyNodeUserStatusBody
   * @typedef {{user_id: string, requestBody: UpdateHierarchyNodeUserStatusBody}} UpdateParticipantStatusParams
   * @param {UpdateParticipantStatusParams} params
   * @returns any
   */
  updateStatus({ user_id, requestBody }) {
    return instance.put(`${baseUrl}${user_id}/`, {
      ...requestBody,
    });
  },
  /**
   * @param {{user_id: string}} params
   * @returns any
   */
  activationEmail({ user_id }) {
    return instance.post(`${baseUrl}${user_id}/activation-key`);
  },
};

const hierarchyNodeUsersQueries = baseApi.injectEndpoints({
  endpoints: builder => ({
    /** @type {import('@reduxjs/toolkit/query/react').QueryDefinition<ParticipantDetailsParams, typeof builder.query, 'api-query', UserBodyDetailed>}*/
    getHierarchyNodeUserDetails: builder.query({
      queryFn: (params, _queryApi, _extraOptions, baseQuery) => {
        return baseQuery(() => hierarchyNodeUsers.details(params));
      },
      providesTags: (_, __, arg) => [
        { type: tagTypes.hierarchyNodeUser, id: arg.user_id },
      ],
    }),
    /** @type {import('@reduxjs/toolkit/query/react').MutationDefinition<UpdateHierarchyNodeUserStatusBody, typeof builder.mutation, 'api-query', UserBody>}*/
    updateHierarchyNodeUserStatus: builder.mutation({
      queryFn: (params, _queryApi, _extraOptions, baseQuery) => {
        return baseQuery(() => hierarchyNodeUsers.updateStatus(params));
      },
      invalidatesTags: (_, __, arg) => [
        { type: tagTypes.hierarchyNodeUser, id: arg.user_id },
      ],
    }),
    /** @type {import('@reduxjs/toolkit/query/react').MutationDefinition<{user_id: string}, typeof builder.mutation, 'api-query', {}}>}*/
    postSendActivationEmail: builder.mutation({
      queryFn: (params, _queryApi, _extraOptions, baseQuery) => {
        return baseQuery(() => hierarchyNodeUsers.activationEmail(params));
      },
    }),
  }),
});

export const {
  useGetHierarchyNodeUserDetailsQuery,
  useLazyGetHierarchyNodeUserDetailsQuery,
  useUpdateHierarchyNodeUserStatusMutation,
  usePostSendActivationEmailMutation,
} = hierarchyNodeUsersQueries;

export default hierarchyNodeUsers;

// TYPES

/**
 * @typedef {Object} ParticipantDetailsParams
 * @property {string} user_id ID du noeud hiérarchique en base de données
 * @property {boolean} [include_address] Si true, retourne les adresses du profil utilisateur dans la propriété legal_entity_address d’une ressource de type HierarchyNodeUser
 * @property {boolean} [include_cku] Si true, retourne les informations de la dernière CKU active du profil dans le propriété point_balence d’une ressource de type HierarchyNodeUser, uniquement si l’utilisateur connecté est administrateur ou est propriétaire du profil
 * @property {boolean} [include_manager] Permet de filtrer uniquement sur les profils appartement à l’utilisateur connecté.
 * @property {boolean} [include_point_balance] Si true, retourne le solde de points du profil utilisateur dans la propriété point_balance d’une ressource de type HierarchyNodeUser, avec les informations sur l’expiration la plus proche. Sinon la propriété n’est pas retournée.
 */

/**
 * @typedef {Object} LegalEntityAddress
 * @property {string} line1 - First line of the address
 * @property {string} line2 - Second line of the address
 * @property {string} line3 - Third line of the address
 * @property {string} postal_code - Postal code
 * @property {string} city - City name
 * @property {string} country - Country name
 * @property {string} company_name - Company name
 */

/**
 * @typedef {Object} LastActiveCku
 * @property {string} uuid - Unique identifier of the CKU
 * @property {string} number - CKU number
 */

/**
 * @typedef {Object} Cku
 * @property {LastActiveCku} last_active_cku - Last active CKU information
 * @property {boolean} order_in_progress - Whether there's an order in progress
 */

/**
 * @typedef {Object} Manager
 * @property {string} first_identifier - Unique identifier of the Manager
 * @property {string} firstname - Manager's first name
 * @property {string} lastname - Manager's last name
 */

/**
 * @typedef {Object} NextExpiration
 * @property {number} available - Number of points available
 * @property {number} usability_end - Timestamp of points expiration
 */

/**
 * @typedef {Object} PointBalance
 * @property {number} available - Total available points
 * @property {NextExpiration} next_expiration - Information about next points expiration
 */

/**
 * @typedef {Object} ContractContext
 * @property {string} code - Contract code
 * @property {string} name - Contract name
 */

/**
 * @typedef {Object} Context
 * @property {ContractContext} contract - Contract information
 */

/**
 * @typedef {Object} UserBody
 * @property {string} uuid - Unique identifier of the user
 * @property {string} email - User's email address
 * @property {string} first_identifier - User's first identifier
 * @property {string} firstname - User's first name
 * @property {string} lastname - User's last name
 * @property {boolean} has_user_account - Is user linked to an account
 * @property {number} created_at
 * @property {number} updated_at
 * @property {import("@spider:src/enums/userRoles").UserRolesType} role - User's role
 * @property {import("@spider:src/enums/participantType").ParticipantTypes} type - User's type
 * @property {import("@spider:src/enums/hierarchyNodeUserStatuses").HierarchyNodeUserStatusesType} status - User's status
 * @property {Context} _context - Context information
 */

/**
 * @typedef {UserBody & {
 *   legal_entity_address?: LegalEntityAddress,
 *   cku?: Cku,
 *   manager?: Manager,
 *   point_balance?: PointBalance
 * }} UserBodyDetailed
 */
